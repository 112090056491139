import axios from 'axios'
import { Commit } from 'vuex'

const url_api4 = process.env.VUE_APP_API_URL_4

export default {
    namespaced: true,
    state: {
        translator: null
    },
    getters: {
        getTranslator: (state: { translator: any }) => state.translator,
    },
    mutations: {
        SET_TRANSLATOR(state: { translator: any }, payload: any) {
            state.translator = payload
        },
    },
    actions: {
        async translator({ commit }: { commit: Commit }, payload: { text: string }) {
            try {
                const response = await axios.post(
                    url_api4,
                    { text: payload.text },
                    {
                        headers: {
                            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }
                )

                const cleanedText = JSON.stringify(response.data.text.replace(/(\r\np>|\r\nbsp;)/g, ''), null, 2)
                commit('SET_TRANSLATOR', cleanedText)
                return cleanedText.replace(/^"|"$/g, "").replace(/\r\n/g, " ")
            } catch (error) {
                console.error("Terjadi kesalahan saat menerjemahkan:", error)
                throw error
            }
        },
    },
}
